<template>
  <div>
    <general-table
      ref="cashCallWalletTable"
      :api-url="apiUrl"
      :columns="fields"
      :search-box="true"
      :selectable="false"
      :export-table="false"
    >

      <template #cell(id)="data">
        #{{ data.item.id }}
      </template>
      <template #cell(action)="data">
        <b-button
          v-if="data.item.status=='success'"
          size="sm"
          variant="primary"
          @click="refund(data.item)"
        >
          Refund
        </b-button>
        <span v-else>Refunded</span>
      </template>
    </general-table>

  </div>
</template>

<script>
import { ref } from '@vue/composition-api'
import axios from 'axios'
import formValidation from '@core/comp-functions/forms/form-validation'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
// import AdminDashboardTable from '@/views/dashboard/admin-dashboard/components/AdminDashboardTable.vue'
import timeAgo from '@/global-compositions/timeAgo'
import GeneralTable from '@/components/general-table/GeneralTable.vue'

export default {
  components: { GeneralTable },
  setup() {
    const report = ref([])
    const { getValidationState } = formValidation()

    const amount = ref('')
    const addBalanceAmount = ref('')
    const loaderEditBalance = false
    const loaderAddBalance = false
    const loadRefund = false

    const fields = [
      { key: 'id', label: '#', sortable: true },
      { key: 'user_name', label: 'Name' },
      { key: 'phone', label: 'Mobile' },
      { key: 'order_id', label: 'Order ID' },
      { key: 'amount', label: 'Amount' },
      {
        key: 'action',
        label: 'Action',
      },
    ]
    const errorMsg = ref('')

    return {
      amount,
      loaderEditBalance,
      loaderAddBalance,
      addBalanceAmount,
      getValidationState,
      ToastificationContent,
      timeAgo,
      loadRefund,
      errorMsg,
      fields,
      report,
    }
  },
  computed: {
    apiUrl() {
      const base = 'list_orders'

      return base
    },
    downloadFile() {
      return `https://v2.zerocash.co/${this.apiUrl}`
    },
  },
  methods: {

    refund(data) {
      this.$swal({
        title: 'Are you sure that you want to refund this order?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes',
      }).then(result => {
        if (result.isConfirmed) {
          const formData = {
            order_id: data.order_id,
          }
          axios.post(`order/${data.id}/refund`, formData).then(res => {
            if (res.status === 200) {
              const msg = res.data.message
              const { status } = res.data
              if (status === 400) {
                this.$swal('error', msg, 'error')
              } else {
                this.$swal(
                  'Refunded!',
                  msg,
                  'success',
                )
              }
              this.$refs.cashCallWalletTable.getAllData()
            }
          })
        }
      })
    },
  },
}
</script>

<style>

</style>
